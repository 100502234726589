import { useQuery } from "@apollo/client"
import React from "react"
import { Redirect } from "react-router"
import { PoolBugUrlQuery, PoolBugUrlQueryVariables } from "../../../../__generated__/PoolBugUrlQuery"
import { TPoolRouteProps } from "../../../../routes.d"
import Status from "../../../Base/Status"
import LoadingView from "../../../components/LoadingView"
import { canUseDom } from "../../../utils/misc-utils"
import { POOL_BUG_URL_QUERY } from "../../queries"

let didRedirect = false

function PoolBug(props: TPoolRouteProps) {
  const { poolData } = props
  const { poolId, entryId } = poolData
  const skip = !poolId
  const poolBugUrlQuery = useQuery<PoolBugUrlQuery, PoolBugUrlQueryVariables>(POOL_BUG_URL_QUERY, {
    variables: { entryId, poolId },
    skip,
  })
  const href = poolBugUrlQuery.data?.pool.bugLinkUrl
  if (href) {
    if (canUseDom) {
      if (!didRedirect) {
        didRedirect = true
        window.location.replace(href)
      }
    } else {
      return (
        <Status code={302}>
          <Redirect to={href} />
        </Status>
      )
    }
  }
  return <LoadingView />
}
export default PoolBug
